import InputText from '@components/Inputs/InputText/InputText';
import styles from './ListSearchForm.module.scss';
import { Dispatch, SetStateAction } from 'react';
import { CrudMetadata } from '../../types';
import Checkbox from '@components/Checkbox/Checkbox';
import Select from '@components/Select/Select';
import { useTranslation } from 'react-i18next';
import DateRangePicker from '@components/DateRangePicker/DateRangePicker';

export default function ListSearchForm({
  searchState,
  setSearchState,
  metadata
}: {
  searchState: {[key: string]: any};
  setSearchState: Dispatch<SetStateAction<{[key: string]: any}>>;
  metadata: CrudMetadata;
}) {

  const { t } = useTranslation();

  const fields = metadata.listView.filters.map(filter => metadata.fields[filter.id]);

  return (
    <div className={styles.container}>

      {fields.map(field => {

        const fieldMeta = metadata.fields[field.id];

        switch (fieldMeta.type) {
          case 'string':
          case 'number':

            return (
              <InputText
                key={field.id}
                label={field.label}
                containerProps={{
                  className: styles.input,
                }}
                inputProps={{
                  type: "text",
                  placeholder: field.label,
                  value: searchState[field.id] || '',
                  onChange: (e) => {
                    setSearchState((searchState) => ({
                      ...searchState,
                      [field.id]: fieldMeta.type === 'number' ? parseFloat(e.target.value) : e.target.value,
                    }));
                  },
                }}
              />
            );

          case 'boolean':
            return (
              <Checkbox
                label={field.label}
                checked={searchState[field.id] || false}
                onChange={(checked) => {
                  setSearchState((searchState) => ({
                    ...searchState,
                    [field.id]: checked
                  }));
                }}
              />
            )

          case 'select':

            return (
              <Select
                noneLabel={t('all')}
                placeholder={t('all')}
                label={field.label}
                optionsV2={(fieldMeta.options || []).map(option => ({
                  label: option.label,
                  value: option.id
                }))}
                selectedIndex={(fieldMeta.options || []).findIndex(option => option.id === searchState[field.id])}
                onChangeIndex={(index) => {
                  setSearchState((searchState) => ({
                    ...searchState,
                    [field.id]: index === null ? undefined :(fieldMeta.options || [])[index].id
                  }));
                }}
              />
            );

          case 'datetime':
            return (
              <DateRangePicker
                label={field.label}
                dateRange={(searchState[field.id] || "").split(';').map((date: string) => (
                  date ? new Date(date) : null
                ))}
                maxDate={fieldMeta.maxDate ? new Date(fieldMeta.maxDate) : undefined}
                onChange={(dateRange) => {
                  setSearchState((searchState) => ({
                    ...searchState,
                    [field.id]: dateRange.map(date => {
                      if (date) {
                        // yyyy-MM-dd
                        return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
                      }
                      return "";
                    }).join(';')
                  }));
                }}
              />
            )
          default:
            return null;
        }
    })}

    </div>
  )
}