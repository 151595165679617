import styles from './List.module.scss';
import withCrudMetadata from '../withCrudMetadata';
import ListSearchForm from './Components/ListSearchForm';
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useDebounce } from '@uidotdev/usehooks';
import { publicApi } from 'src/Api/api';
import { CrudMetadata, Entity, EntityItem, ListResponse } from '../types';
import ListSearchResults from './Components/ListSearchResults/ListSearchResults';
import { useConfirmAlert } from 'src/Stores/ConfirmAlertStore';
import { useTranslation } from 'react-i18next';
import Button from '@components/Button/Button';
import ModalEditCreateCrud from '../Edit/ModalEditCreateCrud';
import ButtonDownloadReceipt from 'src/Pages/CurrentMission/Pages/ItineraryRecap/SelfCare/Components/ButtonDownloadReceipt/ButtonDownloadReceipt';
import { useLocation } from 'react-router-dom';
import ModalFileImport from './Components/ImportModal/ImportModal';

function useStateWithDebounce<T>(value: T, delay: number): [T, Dispatch<SetStateAction<T>>, T] {
  const [state, setState] = useState<T>(value);

  const debouncedValue = useDebounce(state, delay);

  return [
    state,
    setState,
    debouncedValue
  ]
}

const Pagination = ({
  offset,
  setOffset,
  limit,
  setLimit,
  total,
  displayInfos = true,
}: {
  offset: number;
  setOffset: Dispatch<SetStateAction<number>>;
  limit: number;
  setLimit: Dispatch<SetStateAction<number>>;
  total: number;
  displayInfos?: boolean;
}) => {

  const { t } = useTranslation();

  return (
    <div className={styles.pagination}>
      <div className={styles.paginationActions}>
        {offset === 0 ? (
          <div />
        ) : (
        <Button
          label={t('previous')}
          onClick={() => {
            setOffset(offset - limit);
          }}
          containerProps={{
            className: styles.buttonPrevious,
            disabled: offset === 0,
          }}
        />
        )}
        {offset + limit >= total ? (
          <div />
        ) : (
          <Button
            label={t('next')}
            onClick={() => {
              setOffset(offset + limit);
            }}
            containerProps={{
              className: styles.buttonNext,
              disabled: offset + limit >= total,
            }}
          />
        )}
      </div>
      {displayInfos && (
        <div className={styles.paginationInfos}>
          <span>
          {t('showing')} {offset + 1} - {Math.min(offset + limit, total)} {t('of')} {total}
          </span>
          <select
            value={limit}
            onChange={(e) => {
              setLimit(parseInt(e.target.value));
              setOffset(0);
            }}
          >
            <option value={2}>2</option>
            <option value={5}>5</option>
            <option value={10}>10</option>
          </select>
        </div>
      )}
    </div>
  )
}

const CrudListPage = withCrudMetadata(function ({
  entity,
  metadata,
}: {
  entity: Entity;
  metadata: CrudMetadata;
}) {

  const location = useLocation();

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);

  const defaultSearchState = metadata.listView.filters.reduce((acc, field) => {
    const searchParams = new URLSearchParams(location.search);
    acc[field.id] = searchParams.get(field.id) || '';
    return acc;
  }, {} as { [key: string]: any });

  const { t } = useTranslation();
  const [searchState, setSearchState] = useState<{
    [key: string]: any;
  }>(defaultSearchState);
  const [sortBy, setSortBy] = useState<{
    field: string;
    direction: 'asc' | 'desc';
  } | null>(null);
  const searchStateDebounced = useDebounce(searchState, 500);
  const [_loading, setLoading, loadingDebounced] = useStateWithDebounce<boolean>(false, 150);
  const [results, setResults] = useState<ListResponse>({
    rows: [],
    total: 0,
  });
  const { confirm } = useConfirmAlert();

  const [modalImportOpened, setModalImportOpened] = useState(false);
  const [modalEditEntityData, setModalEditEntityData] = useState<EntityItem | null>(null);
  const [modalCreateEntityOpened, setModalCreateEntityOpened] = useState(false);

  const fetchList = useCallback(async () => {
    setLoading(true);
    try {
      const response = await publicApi.request({
        method: metadata.listView.action.method.toUpperCase(),
        url: metadata.listView.action.href,
        params: {
          ...searchStateDebounced,
          orderBy: sortBy ? sortBy.field : undefined,
          order: sortBy ? sortBy.direction : undefined,
          limit,
          offset,
        },
      })
      console.log(response);
      setResults(response.data);
    } catch(e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }, [searchStateDebounced, sortBy, metadata.listView.action, setLoading, limit, offset]);

  const searchStateHash = Object.values(searchStateDebounced).sort().join('---');
  useEffect(() => {
    setOffset(0);
  }, [
    searchStateHash,
    sortBy
  ]);

  useEffect(() => {
    fetchList();
  }, [fetchList]);

  return (
    <>

      {modalImportOpened && metadata.importAction && (
        <ModalFileImport
          action={metadata.importAction}
          onRequestClose={() => {
            setModalImportOpened(false)
          }}
        />
      )}

      {modalEditEntityData && (
        <ModalEditCreateCrud
          formType='edit'
          entityItem={modalEditEntityData}
          onRequestClose={() => setModalEditEntityData(null)}
          onSaved={async () => {
            await fetchList();
          }}
        />
      )}

      {modalCreateEntityOpened && (
        <ModalEditCreateCrud
          formType='create'
          onRequestClose={() => setModalCreateEntityOpened(false)}
          onSaved={async () => {
            await fetchList();
          }}
        />
      )}

      <div className={styles.formTitleLine}>
        <div className={styles.formTitle}>{entity.label}</div>

        {metadata.createView && (
          <Button
            label={t('create')}
            type="primary"
            testid={`button-create-crud-${entity.id}`}
            onClick={() => {
              setModalCreateEntityOpened(true);
            }}
            containerProps={{
              className: styles.buttonCreateCase,
            }}
          />
        )}

        <div className="flex justify-end gap-2 align-center">
          {metadata.exportNav && (
            <ButtonDownloadReceipt
              href={metadata.exportNav.href}
              label={t('export')}
              containerProps={{
                className: styles.buttonCreateCase,
              }}
            />
          )}

          {metadata.importAction && (
            <Button
              label={t('import')}
              type="secondary"
              onClick={() => {
                setModalImportOpened(true);
              }}
              containerProps={{
                className: styles.buttonImport,
              }}
            />
          )}
        </div>


      </div>
      <div className={styles.form}>

        <ListSearchForm
          metadata={metadata}
          searchState={searchState}
          setSearchState={(newState) => {
            setSearchState(newState);
          }}
        />

      </div>

      <ListSearchResults
        onClickSort={(fieldId) => {
          const newSortBy: {
            field: string;
            direction: 'asc' | 'desc';
          } = {
            field: fieldId,
            direction: sortBy?.field === fieldId && sortBy.direction === 'asc' ? 'desc' : 'asc',
          };
          setSortBy(newSortBy);
        }}
        sortedBy={sortBy?.field}
        sortedDirection={sortBy?.direction}
        metadata={metadata}
        containerProps={{
          className: styles.results,
        }}
        theadClassName={styles.thead}
        isLoading={loadingDebounced}
        results={results.rows || []}
        onClickAction={(actionKey, entityObject) => {
          console.log(actionKey, entityObject);
          if (actionKey === 'update') {
            setModalEditEntityData(entityObject);
            return;
          }
          confirm({
            title: t('are_you_sure') + ' - ' + t(actionKey),
            message: t('this_action_cannot_be_undone'),
            confirmLabel: t('button.confirm'),
            cancelLabel: t('cancel'),
            onConfirm: async () => {
              const action = entityObject.actions?.[actionKey];
              if (!action) {
                console.error('No action defined');
                return;
              }
              await publicApi.request({
                method: action.method,
                url: action.href,
              });

              await fetchList();
            },
            onCancel: () => {
              console.log('cancelled');
            }
          });
        }}
      />

      <Pagination
        offset={offset}
        setOffset={setOffset}
        limit={limit}
        setLimit={setLimit}
        total={results.total}
      />
    </>
  );
});

export default CrudListPage;