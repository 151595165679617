import Button from '@components/Button/Button';
import Modal from '@components/Modal/Modal';
import styles from './ModalConfirm.module.scss'
import { useConfirmAlert } from 'src/Stores/ConfirmAlertStore';
import { CheckNotValid } from '@assets/icons/icons';
/*
content="Voulez-vous vraiment modifier la recherche ?"
        confirmText="Oui"
        cancelText="Non"
        onConfirm={() => { }}
        onCancel={() => { }}

*/
export default function ModalAlert({
  title,
  message,
  confirmLabel = "ok",
  layout = 'default',
  message_type,
  onClose,
}: {
  title: string;
  message: string;
  confirmLabel: string;
  cancelText: string;
  layout?: 'default' | 'minimal';
  message_type?: 'default' | 'success' | 'error';
  onClose?: () => void;
}) {

  const { close } = useConfirmAlert();

  return (
    <Modal
      title={(
        <div className={styles.modalTitle}>
          {message_type === 'error' && (
            <CheckNotValid />
          )}
          {title}
        </div>
      )}

      containerProps={layout === 'minimal' ? {
        style: {
          maxWidth: 600,
        },
        className: `${styles.modalConfirmContainer} ${layout ? styles[layout] : ""} ${styles["type_" + (message_type || 'default')]}`
      } : {}}

      contentContainerProps={{
        className: `${styles.modalConfirmContent}`
      }}

      layout={layout}

      onRequestClose={() => {
        close();
        onClose && onClose();
      }}
    >
      <div className={styles.messageContainer}>
        {message}
      </div>
      <div className={styles.buttonActions}>
        <Button
          label={confirmLabel}
          colorType={message_type}
          onClick={() => {
            close();
            onClose && onClose();
          }}
        />
      </div>
    </Modal>
  )
}