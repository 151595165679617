import { useInterval } from 'usehooks-ts';
import styles from './CurrentHour.module.scss';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function CurrentHour({
  userName,
  containerProps: { className, ...containerProps } = {},
}: {
  userName?: string;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {

  const { t } = useTranslation()

  const getCurrentHour = () => {
    return (new Date()).toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' })
  }
  const [currentHour, setCurrentHour] = useState(getCurrentHour());

  // get current timezone like "UTC +2" or "UTC -3" (offset)
  const offset = - (new Date()).getTimezoneOffset() / 60;
  const timezone = `UTC ${offset > 0 ? "+" : ""}${offset}`;

  useInterval(() => {
    setCurrentHour(getCurrentHour());
  }, 60000);


  return (
    <div className={[styles.currentHourContainer, className].join(" ")} {...containerProps}>
      <div className={styles.currentHour}>{currentHour}</div>
      <div className={styles.currentHourInfo}>
        {t('currentLocalTime')} {timezone}
      </div>
      {userName && (
        <div className={styles.username}>
          {userName}
        </div>
      )}
    </div>
  )
}